import { ready } from './helpers';

import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from '!mapbox-gl';

ready(() => {
  const locationMapElement = document.getElementById('map');

  if (!(locationMapElement && locationMapElement.dataset.coords)) {
    return
  }

  mapboxgl.accessToken = 'pk.eyJ1IjoiY2FtYnJpZGdlYXJ0c25ldHdvcmsiLCJhIjoiY2t6c2tzcXBuMHRiOTJ2bzl2bXpjMzhmbyJ9.JM07cVOFHFpAI0EBT4FuxA';

  const coords = locationMapElement.dataset.coords.split(',');

  const map = new mapboxgl.Map({
    container: locationMapElement.id, // container ID
    // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
    style: 'mapbox://styles/mapbox/streets-v11', // style URL
    center: coords, // starting position [lng, lat]
    zoom: 17, // starting zoom
    projection: 'globe' // display the map as a 3D globe
  });

  const el = document.createElement('div');

  el.className = 'marker';

  new mapboxgl.Marker(el).setLngLat(coords).addTo(map);
});
