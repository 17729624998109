/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import bootstrap from 'bootstrap';
import trix from 'trix';

import railsUjs from './rails_ujs';
import ajaxSetup from './ajax_setup';
import choices from './choices';
import clipboard from './clipboard';
import flatpickr from './flatpickr';
import mapbox from './mapbox';
import newsletterSubscribe from './newsletter_subscribe';
import newsletterSubscriptions from './newsletter_subscriptions';
import submitterType from './submitter_type';
import toastr from './toastr';

import '../styles/application.scss';
