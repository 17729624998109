import { ready } from './helpers';

import Flatpickr from 'flatpickr';

ready(() => {
  new Flatpickr('input[type="date"][data-picker="true"]', {
    allowInput: true,
    altInput: true,
    altFormat: "d/m/Y",
  });

  new Flatpickr('input[type="datetime"][data-picker="true"], input[type="datetime-local"][data-picker="true"]', {
    enableTime: true,
    allowInput: true,
    altInput: true,
    altFormat: "d/m/Y h:i K",
  });

  new Flatpickr('input[type="time"][data-picker="true"]', {
    enableTime: true,
    noCalendar: true,
    allowInput: true,
    altInput: true,
    altFormat: "h:i K"
  });
});
