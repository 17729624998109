import { ready } from './helpers';

ready(() => {
  $('[name$="[submitter_id]"]').on('change', function(e) {
    var type = e.target.options[e.target.selectedIndex].getAttribute(
      'data-type',
    );

    if (!type) {
      return;
    }

    var modelName = type.charAt(0).toUpperCase() + type.slice(1);
    $('[name$="[submitter_type]"]').val(modelName);
  });
});
