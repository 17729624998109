import { ready } from './helpers';

import Choices from 'choices.js';

ready(() => {
    document.querySelectorAll('.art_forms_disciplines').forEach(element => {
        new Choices(element, {
            placeholder: true,
            placeholderValue: 'Choose one or more...',
            removeItemButton: true,
        });
    });

    document.querySelectorAll('.type_of_role').forEach(element => {
        new Choices(element, {
            placeholder: true,
            placeholderValue: 'Choose one or more...',
            removeItemButton: true,
        });
    });

    document.querySelectorAll('[data-role="tags"]').forEach(element => {
        new Choices(element, {
            placeholder: true,
            placeholderValue: 'Add one or more...',
            removeItemButton: true,
        });
    });

    document.querySelectorAll('.space_type').forEach(element => {
        new Choices(element, {
            placeholder: true,
            placeholderValue: 'Choose one or more...',
            removeItemButton: true,
        });
    });

    document.querySelectorAll('.licences').forEach(element => {
        new Choices(element, {
            placeholder: true,
            placeholderValue: 'Choose one or more...',
            removeItemButton: true,
        });
    });
});
