export const ready = fn => {
	if (document.readyState != 'loading') {
		fn();
	} else {
		document.addEventListener('DOMContentLoaded', fn);
	}
};

export const classExists = (className) => document.getElementsByClassName(className).length > 0;

export const getClasses = (el) => el.className.split(' ');

export const setClasses = (el, classNames) => { el.className = classNames.filter(c => !!c).join(' ') };

export const addClass = (el, className) => {
	let classes = getClasses(el);

	classes.push(className);

	setClasses(el, classes);
};

export const removeClass = (el, className) => {
	let classes = getClasses(el);

	let existingIndex = classes.indexOf(className);

	if (existingIndex !== -1) {
		classes.splice(existingIndex, 1);
		setClasses(el, classes);
	}
};

export const hasClass = (el, className) => getClasses(el).indexOf(className) >= 0;

export const toggleClass = (el, className) => {
	if (hasClass(el, className)) {
		removeClass(el, className);
	} else {
		addClass(el, className);
	}
};