import { ready } from './helpers';

ready(() => {
  $('.subscribe').on('click', function (e) {
    var _this = this;
    e.preventDefault();
    var channel_name = $(this).data('name'),
      channel_path = $(this).data('path');
    $.ajax({
      url: '/' + channel_path + '/subscribe',
      type: 'POST',
      data: {
        channel_name: channel_name,
        format: 'json'
      },
      dataType: 'json',
      success: function (data) {
        if (data.subscribed === true) {
          $('span', _this).text('Unsubscribe');
          $(_this).closest('.can-newsletter-subscription').removeClass('unsubscribed');
          $(_this).closest('.can-newsletter-subscription').addClass('subscribed');
        } else {
          $('span', _this).text('Subscribe');
          $(_this).closest('.can-newsletter-subscription').removeClass('subscribed');
          $(_this).closest('.can-newsletter-subscription').addClass('unsubscribed');
        }

      }
    });
  });
});
