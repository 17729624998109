import { ready } from './helpers';

ready(() => {
  $('input[name="user[subscriptions_attributes][][channel]"]').on(
    'change',
    function(e) {
      var checked = $(this).is(':checked');
      $(this)
        .siblings('input[name="user[subscriptions_attributes][][_destroy]"]')
        .val(!checked);
    },
  );
});
